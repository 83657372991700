import './App-header.css'

function AppHeader() {
    return (
        <div className="App-header">
            <h1>EPS-URL</h1>
        </div>
    );
}

export default AppHeader;